@use 'sass:math';
@import '_custom-variables.scss';
body {
  font: normal normal 1.6rem Arial !important;
}

// spinner color
.loading-roller div:after {
  background: red !important;
}

.mat-mdc-dialog-title.tc-dialog-title {
  font: var(--dialog-title-font);
  border-bottom: var(--dialog-title-border-bottom);
}

.mat-mdc-dialog-actions.tc-dialog-actions {
  background-color: var(--dialog-actions-background);
  // We do not require actions for dialogs in AMS so hide the actions container
  display: none;
}

mat-toolbar.tc-layout-toolbar {
  color: #000000;
}

// Makes page non-scrolable
.page {
  $padding: 2rem;

  padding: $padding;
  height: calc(100% - (#{$padding} * 2));
  overflow: hidden;

  display: flex;
  flex-direction: column;
}

.grid {
  flex-grow: 1;
  margin-bottom: 3rem;
}

//Set the grid detail pop-up to be as large as possible on small screens
.extra-small-device {
  .cdk-overlay-container
    .cdk-global-overlay-wrapper
    .cdk-overlay-pane.tc-grid-detail-popup {
    max-width: 100% !important;
    width: 100% !important;
    height: 90% !important;
  }
}

.small-device {
  .cdk-overlay-container
    .cdk-global-overlay-wrapper
    .cdk-overlay-pane.tc-grid-detail-popup {
    max-width: 95% !important;
    width: 95% !important;
    height: 90% !important;
  }
}

.medium-device,
.large-device {
  .cdk-overlay-container
    .cdk-global-overlay-wrapper
    .cdk-overlay-pane.tc-grid-detail-popup {
    max-width: 90% !important;
    width: 90% !important;
    height: 90% !important;
  }
}

.login-title {
  div.page-header-container {
    display: none;
  }
}

app-interactions-grid,
app-interactions-history-grid,
app-interlocuteurs-grid {
  .ag-theme-alpine {
    .ag-header-cell,
    .ag-cell {
      &:first-of-type {
        padding-left: 1rem;
      }

      &:last-of-type {
        padding-right: 1rem;
      }
    }
  }
}

mat-toolbar {
  .left {
    .logo {
      height: 6.7rem !important;
      margin-left: 1.6rem !important;
    }
  }

  .right {
    color: #fff;

    .current-user-info {
      display: flex;
      flex-direction: column;
      row-gap: 1rem;
      align-items: flex-start;
    }

    min-width: 0;
    overflow: hidden;
  }
}

@font-face {
  font-family: 'Open Sans Condensed';
  src: url('./assets/fonts/OpenSans-CondBold.ttf') format('truetype');
}

/*#region MatDialog*/
.cdk-overlay-container .mdc-dialog__container .mat-mdc-dialog-surface {
  border-radius: 0;

  > *:first-child {
    tc-dialog-content,
    .tc-prompt-dialog-container,
    .tc-help-dialog-container {
      $dialog-white-space: 1.6rem;

      tc-dialog-header {
        background-color: var(--tc-dialog-header-background-color);

        .tc-dialog-header,
        .tc-prompt-dialog-header,
        .tc-help-dialog-header {
          border-bottom: none;

          .dialog-title {
            color: white;

            .title {
              font-size: 2rem !important;
            }
          }

          .dialog-actions {
            section:last-of-type {
              mat-icon {
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 3.4rem;
                line-height: 3.4rem;
                color: #786f78;
              }
            }
          }
        }
      }

      tc-dialog-footer,
      .tc-prompt-dialog-footer,
      .tc-help-dialog-footer {
        background-color: white;

        .dialog-actions {
          display: flex;
          justify-content: flex-end;
        }
      }

      tc-dialog-footer {
        padding: $dialog-white-space;
      }

      .tc-prompt-dialog-footer,
      .tc-help-dialog-footer {
        padding: math.div($dialog-white-space, 2) $dialog-white-space;
      }

      .tc-dialog-content,
      .tc-prompt-dialog-content,
      .tc-help-dialog-content {
        padding: 0 $dialog-white-space * 0.5 + 0.2rem;
        margin: $dialog-white-space $dialog-white-space * 0.5 - 0.2rem;
      }
    }
  }
}

tc-vertical-layout {
  .mat-mdc-list-base {
    tc-list-nav-item {
      &:not(:first-child) {
        &::before {
          background: var(--homepage-link-color);
        }
      }

      .mat-mdc-list-item {
        font-size: 1em;

        span.label {
          line-height: 1.1em !important;
        }

        &:not(.active-link) {
          .label {
            transition-duration: 0.5s;
          }
        }

        &:hover {
          .tc-nav-menu-data {
            border-bottom: 0.5rem solid var(--tc-nav-item-separator-color);

            .label {
              color: var(--tc-nav-item-separator-color);
            }
          }
        }

        &.active-link {
          .tc-nav-menu-data {
            border-bottom: 0.5rem solid var(--tc-nav-item-separator-color);

            .label {
              color: var(--tc-nav-item-separator-color);
            }
          }
        }
      }
    }
  }
}

.tc-nav-menu-data .icon-container mat-icon,
.tc-nav-menu-data .label {
  color: var(--homepage-link-color) !important;
}

.mat-drawer-container {
  background-color: var(--tc-mat-container-background);
}

tc-smart-button {
  .mat-accent {
    background-color: var(--tc-accent-color) !important;
    color: #ffffff !important;
    padding: 0.5em !important;
    border: 0.125em solid var(--tc-accent-color) !important;
    border-radius: 0.3125em !important;
  }

  .mat-primary {
    background-color: var(--tc-smart-button-background-color);
    color: var(--tc-mat-primary-button-color) !important;
    border: var(--tc-smart-button-border);
    border-radius: 0.3125em !important;
    padding: 0.5em !important;
  }
}

// Only un-intrusive way to style the confirm button on the prompt dialog
tc-prompt-dialog tc-smart-button .mat-raised-button.mat-primary {
  background-color: var(--tc-confirm-dialog-yes-button-background);
  color: var(--tc-confirm-dialog-yes-button-color) !important;
  border-color: var(--tc-confirm-dialog-yes-button-border-color) !important;
}

tc-grid-smart-buttons-renderer {
  .mat-accent {
    background: none !important;
    color: var(--tc-accent-color) !important;
    padding: 0.5em !important;
    border: none !important;
  }
}

.mat-mdc-form-field-infix {
  min-height: 50px !important;
  padding-bottom: 0 !important;
}

.mdc-text-field {
  padding: 0 !important;
}

.mdc-text-field--filled:not(.mdc-text-field--disabled)  {
  background: transparent !important;
}

.mat-mdc-form-field-focus-overlay {
  background: transparent !important;
}

.mdc-text-field--disabled.mdc-text-field--filled {
  background: transparent;
}

.mat-radio-checked:not(.mat-radio-disabled) {
  .mat-radio-outer-circle {
    border-color: var(--topnav-backgroud-color) !important;
  }

  .mat-radio-inner-circle {
    background-color: var(--topnav-backgroud-color) !important;
  }
}

.mat-checkbox-indeterminate .mat-accent .mat-checkbox-background,
.mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: var(--topnav-backgroud-color) !important;
}

.text-align-right {
  text-align: right;
}
/*#endregion */

// Override the small date-picker and set max-width to unset to use colSpan instead
.small-date-picker {
  mat-form-field {
    max-width: unset;
  }
}

// Hide arrow elements from inputs of type number
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

// AMS-9 
app-received-delivery-notes-page {
  padding-bottom: 20em;
  .page {
    overflow: visible;
  }
}

// AMS-24 
app-associations-page {
  .stats {
    .categories-stats,
    .software-stats {
      app-ams-stats-table {
          .cell-text{
            font-size: 0.87em!important;
            margin: 0.25em!important;
          }
      }
    }
  }
}

// Text ellipsis for grid cells with text that is too long
.grid-cell-text-ellipsis {
  padding: 0;

  span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

// Button ripples for action buttons
.actions-button {
  .mat-mdc-icon-button .mat-ripple-element {
    --mat-mdc-button-ripple-color: #ff7f0019 !important;
  }
}

.mdc-button.mat-mdc-button.mat-primary.mat-mdc-button-base .mat-ripple.mat-mdc-button-ripple .mat-ripple-element {
  --mat-mdc-button-ripple-color: #78786F19 !important;
}

// Form field bottom border color
.mdc-text-field--filled .mdc-line-ripple::after {
  border-bottom-color: var(--topnav-backgroud-color) !important;
}
.mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
  border-bottom-color: rgba(144, 144, 144, 0.42);
}